import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})

export class MenuService {
    constructor() {
        this.isDesktopDevice();
      }

    public isDesktopDevice(): boolean {
        return window.innerWidth > 768; 
    }

    public menu: Menu = null;
    private menuSubject = new BehaviorSubject(this.menu);

    private menuOpenedSubject = new BehaviorSubject<boolean>(this.isDesktopDevice());
    public menuOpened$ = this.menuOpenedSubject.asObservable();

    public currentTabSubject = new BehaviorSubject<string>('approved');
    currentTab$ = this.currentTabSubject.asObservable();
    
    setCurrentTab(tabOption: string) {
      this.currentTabSubject.next(tabOption);
    }
  
    getCurrentTab() {
      return this.currentTabSubject.value;
    }
    

    getMenuObservable(): Observable<Menu> {
        return this.menuSubject.asObservable();
    }

    getMenuOpened(): Observable<boolean> {
        return this.menuOpened$;
    }

    public setMenu(menu: Menu) {
        this.menu = menu;
        this.menuSubject.next(this.menu);
    }

    openMenu() {
        this.menuOpenedSubject.next(true);
    }

    closeMenu() {
        this.menuOpenedSubject.next(false);
    }
}

export class Menu {
    public closed_menu : boolean;

    constructor(closed_menu: boolean) {
        this.closed_menu = closed_menu;
    }

    getMenu(){
        return this.closed_menu;
    }
}