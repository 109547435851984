import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, map, tap } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { NotificationService } from 'src/app/services/notification.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
    selector: 'app-layout',
    templateUrl: './template.html',
    styleUrls: ['./style.css'],
    animations: [
        trigger('slideInAnimation', [
          state('in', style({ transform: 'translateX(0)' })),
          transition(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('300ms ease-out')
          ]),
          transition(':leave', [
            animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
          ])
        ])
      ]
})

export class ComponentInsideLayout implements OnInit {

    menu$ = this.menuService.menuOpened$.pipe(
        map((menu) => {
            return {
                isOpen: menu
            };
        })
    );

    notificationsSidebar$ = this.notificationService.notificationSidebarOpened$.pipe(
        map((notificationSidebar) => {
            return {
                isOpen: notificationSidebar
            };
        })
    );

    vm$ = combineLatest([this.menu$, this.notificationsSidebar$]).pipe(
        map(([menu, notificationsSidebar]) => {
            return {
                menu,
                notificationsSidebar
            };
        })
    );

    constructor(
        public Router: Router,
        private menuService: MenuService,
        private notificationService: NotificationService

    ) { }

    ngOnInit() {
    }

    openMenu() {
        this.menuService.openMenu();
    }
}