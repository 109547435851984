import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { User, UserMenuService } from 'src/app/services/user-header.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AppConfig } from 'src/app/app.config';
import { firstValueFrom, map, BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { MenuService } from 'src/app/services/menu.service';
import config from 'src/app/configuration_file';
import { filter } from 'rxjs/operators';
import { Observable, take, tap } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public photo_url = AppConfig.api_url + "public/uploads/user/";
    private location: Location;
    private onNotification = {
        review: false,
        diffusion: false,
        notification: false
    };
    public loadMore = false;

    public User = {
        name: "",
        id: "",
        paternal_surname: "",
        maternal_surname: "",
        email: "",
        image: "",
        role_id: 2
    };
    public isFrontInProd: boolean;
    public isBackInProd: boolean;
    public mostrarNotificacion:boolean;

    public darkLogoRoute = config.logo === 'default' ? '../../../assets/resources/logos/Logo-Green-M3.png' : '../../../assets/resources/logos/custom/' + config.business_name + '_dark.png';

    menu$ = this.menuService.menuOpened$.pipe(
        map((menu) => {
            return {
                isOpen: menu
            };
        })
    );

    public notifications$: Observable<Notification[]>;
    public allNotifications: Notification[] = []; 

    constructor(
        location: Location,
        private Router: Router,
        private UserMenuService: UserMenuService,
        private NotificationService: NotificationService,
        private ApiService: ApiService,
        private userService: UserService,
        private menuService: MenuService
    ) {
        this.location = location;
    }

    async ngOnInit() {
        await this.getUser();
        this.isFrontInProd = AppConfig.production;
        this.isBackInProd = (await firstValueFrom<any>(this.ApiService.get({url: 'environment_flag'}))).production;
        this.UserMenuService.getUserObservable().subscribe((User) => {
            if (User && User.getUser().id != "") {
                this.User = User.getUser();
            }
        })

        this.notifications$ = this.NotificationService.sidebarNotifications$;
        await this.getNotifications();

        this.Router.events.subscribe(async (event) => {
            if (event instanceof NavigationEnd) {
                this.notifications$ = this.NotificationService.sidebarNotifications$;
                await this.getUser();
                await this.getNotifications();
            }
        });
    }

    async getNotifications() {
        this.notifications$.pipe(
            filter(notifications => !!notifications && notifications.length > 0),
            tap((notifications: Notification[]) => {
                this.allNotifications = notifications;
                this.NotificationService.allNotifications = this.allNotifications;
                const algunaNoLeida = this.allNotifications.some(notification => notification.read !== "1");
                if (this.allNotifications.length > 0 && algunaNoLeida) {
                    this.mostrarNotificacion = true;
                } else {
                    this.mostrarNotificacion = false;
                }
            })
        ).subscribe();
    }
    
    public user:any;
    // Get perfil de mi usuario
    async getUser(): Promise<void> {
        const user = await firstValueFrom<any>(this.userService.retrieveUser());
        if (user) {
            this.user = user;
        } 
    }

    getTitle() {
        const path = this.location.prepareExternalUrl(this.location.path());
        let title = '';
        if (path.includes('/home')) {
            title = 'Panel de Control';
        } else if (path.includes('/document/folder')) {
            title = 'Documentos';
        } else if (path.includes('/document/list')) {
            title = config.menu.documentos.text_SIGCI;
        } else if (path.includes('/document/master-list')) {
            title = 'Listado Maestro';
        } else if (path.includes('/document/create')) {
            title = 'Crear Documento';
        } else if (path.includes('/document/assessment')) {
            title = 'Inspección de Documentos';
        } else if (path.includes('/document/indicators')) {
            title = 'Indicadores de Documentos';
        } else if (path.includes('/users-list')) {
            title = 'Directorio';
        } else if (path.includes('/document/diffusion-notification')) {
            title = 'Notificaciones';
        } else if (path.includes('/profile')) {
            title = 'Mi perfil';
        } else if (path.includes('/user-edit')) {
            title = 'Dar de alta usuario';
        } else if (path.includes('/task')) {
            title = 'Mis tareas';
        } else if (path.includes('/document/template')) {
            title = 'Elaboración de Documentos';
        } else if (path.includes('/training')) {
            title = 'Capacitaciones Asignadas';
        } else if (path.includes('/document/edit')) {
            title = 'Control de Documentos';
        } else if (path.includes('/document/flow')) {
            title = 'Flujo del Documento';
        } else if (path.includes('/document/approve')) {
            title = 'Aprobación del documento';
        } else if (path.includes('/document/diffusion')) {
            title = 'Difusión del documento';
        } else if (path.includes('/document/rejected')) {
            title = 'Documento rechazado';
        } else if (path.includes('/user-request')) {
            title = 'Lista de solicitudes de cuentas';
        } else if (path.includes('/chat')) {
            title = 'Mis Mensajes';
        } else if (path.includes('/document/template-dashboard')) {
            title = 'Plantillas';
        } else if (path.includes('/methodology')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/pcc')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/haccp')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/risk-management')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/specification')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/flowchart')) {
            title = 'Editor de diagramas de flujo';
        } else if (path.includes('/hazard-analysis')) {
            title = 'Análisis de Peligro';
        } else if (path.includes('/haccp-plan')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/monitoring')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/verification-risks')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/actions-record')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/action-create')) {
            title = 'Gestión de Riesgos';
        } else if (path.includes('/template-index')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/template-save')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/diary')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/start-inspection')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/inspection')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/action')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/report')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/general-report')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/analysis')) {
            title = 'Inspección de Auditorías';
        } else if (path.includes('/document/extern-file')) {
            title = 'Documentos Externos';
        } else if (path.includes('/document/folder-extern-file')) {
            title = 'Documentos Externos';
        } else if (path.includes('/document/document-request')) {
            title = 'Solicitudes de documentos';
        } else if (path.includes('/notification-inbox')) {
            title = 'Bandeja de Entrada';
        } else if (path.includes('/role')) {
            title = 'Roles de Usuario';
        } else if (path.includes('/rac-list')) {
            title = 'Gestión de Incidentes';
        }
        return title;
    }

    logout() {
        this.User = { name: "", id: "", paternal_surname: "", maternal_surname: "", email: "", image: "", role_id: 2 };
        this.UserMenuService.setUser(new User("", "", "", "", ""));
        this.onNotification.notification = false;
        this.userService.logout();
        this.Router.navigate(['/login']);
        window.location.reload();
    }

    // isReadedNotifications() {
    //     let is_readeds = true;
    //     for (const notification of this.Notifications) {
    //         if (notification.read == 0) {
    //             is_readeds = false;
    //             break;
    //         }
    //     }
    //     return is_readeds;
    // }

    openPage(page_open) {
        this.Router.navigate([page_open]);
    }

    openNotificationsSidebar() {
        this.getNotifications();
        this.NotificationService.openNotificationsSidebar();
    }
}
