<ng-container *ngIf="vm$ | async as vm">
    <button class="btn open-menu" (click)="openMenu()" *ngIf="vm.menu.isOpen === false" type="button" aria-label="open menu">
        <i class="fa fa-bars fa-2x"></i>
    </button>
    
    <div class="page-container">
        <ng-container class="menu-container" *ngIf="vm.menu.isOpen">
            <app-menu   [@slideInAnimation]="'in'" class="sliding-menu"></app-menu>
        </ng-container>
        <div class="main-container">
            <div style="width: 100%;">
                <app-header></app-header>
            </div>
            <div style="width: 100%;" class="router-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <ng-container *ngIf="vm.notificationsSidebar.isOpen">
        <app-notifications-sidebar></app-notifications-sidebar>
    </ng-container>
</ng-container>
